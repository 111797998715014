/* Poppins font */

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Poppins Light'), local('Poppins-Light'), url(../fonts/Poppins/Poppins-Light.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Poppins Regular'), local('Poppins-Regular'), url(../fonts/Poppins/Poppins-Regular.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Poppins Medium'), local('Poppins-Medium'), url(../fonts/Poppins/Poppins-Medium.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Poppins SemiBold'), local('Poppins-SemiBold'), url(../fonts/Poppins/Poppins-SemiBold.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Poppins Bold'), local('Poppins-Bold'), url(../fonts/Poppins/Poppins-Bold.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Poppins Black'), local('Poppins-Black'), url(../fonts/Poppins/Poppins-Black.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* GT-Walsheim font */

@font-face {
  font-family: GT-Walsheim;
  font-weight: 400;
  src: url(../fonts/GT-Walsheim/GT-Walsheim-Regular.woff2) format("woff2");
}

@font-face {
  font-family: GT-Walsheim;
  font-weight: 500;
  src: url(../fonts/GT-Walsheim/GT-Walsheim-Medium.woff2) format("woff2");
}

@font-face {
  font-family: GT-Walsheim;
  font-weight: 600;
  src: url(../fonts/GT-Walsheim/GT-Walsheim-Bold.woff2) format("woff2");
}

@font-face {
  font-family: GT-Walsheim;
  font-weight: 700;
  src: url(../fonts/GT-Walsheim/GT-Walsheim-Black.woff2) format("woff2");
}

/* --------------------------------- */

/* ROOT */

:root {
  --co-1: #0069ff;
  --co-2: #004c9b;
  --co-4: #050505;
}

/* --------------------------------- */

/* MAIN PROPERTIES */

*:not(i) {
  font-family: 'Poppins', sans-serif;
}

html {
  overflow-x: hidden;
  overflow-y: auto;
}

body {
  font-size: 14px;
  color: #050505;
}

::-webkit-scrollbar-thumb {
  background: var(--co-1);
}
::-webkit-scrollbar {
  width: 8px;
}

@media(max-width: 767.98px) {
  ::-webkit-scrollbar {
    width: 4px;
  }
}

/* --------------------------------- */

/* LINKS & BUTTONS */

a, button {
  text-decoration: none !important;
  outline: none !important;
  border: 0;
  cursor: pointer;
}

/* shape (3) */
.theme-btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #FFF !important;
  font-size: 17px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  z-index: 2;
  padding: 17px 45px;
  background: var(--co-1);
  border-radius: 50px;
  border: 1px solid var(--co-1);
  transition: all .25s;
}
.theme-btn .icon {
  width: 20px;
}
.theme-btn:hover {
  background: var(--co-2);
  border-color: var(--co-2);
}
/* wide button */
.theme-btn-wide {
  padding-left: 46px;
  padding-right: 46px;
}
.theme-btn-outline {
  background: transparent;
  border: 3px solid #fff;
}
/* media query */
@media(max-width: 575.98px) {
  .theme-btn {
    width: 100%;
  }
}
/* --------------------------------- */

/* BACKGROUNDS */
.dark_bg {
  background: var(--co-4);
}
/* --------------------------------- */

/* SPCAE */
.space_sm {
  height: 40px;
}
.space_lg {
  height: 80px;
}
.space_xl {
  height: 120px;
}
.py_lg {
  padding: 100px 0;
}
.py_xl {
  padding: 150px 0;
}
.pb_xl {
  padding-bottom: 150px;
}
@media(max-width: 767.98px) {
  .py_lg {
    padding: 70px 0;
  }
  .py_xl {
    padding: 100px 0;
  }
  .pb_xl {
    padding-bottom: 100px;
  }
}
/* --------------------------------- */

/* NAVBAR */
@media(max-width:1023px) {
  nav ul li {
    width: 100%;
    height: 20%;
    overflow: hidden;
    transform: translateX(-100%)
  }
  nav ul li a {
    top: 46%;
    color: #FFFFFF !important;
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 3px;
  }
  .nav_layer {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    background: #121e2c;
    transform: translateX(-100%)
  }
  .layer_down {
    transition: all .4s ease-in-out;
    transform: translateX(0)
  }
  .layer_up {
    transition: all .8s ease-in-out;
    transform: translateX(-100%)
  }
}
@media(min-width:1024px) {
  nav ul li {
    flex-basis: 20%;
    height: 100%;
    overflow: hidden;
    transform: translateY(-100%)
  }
  nav ul li a {
    top: 46%;
    color: #FFFFFF;
    font-weight: 300;
    font-size: 16px;
    letter-spacing: 3px;
  }
  nav li a:before {
    content: '';
    width: 10px;
    height: 2px;
    background-color: #FFFFFF;
    position: absolute;
    top: 50%;
    left: 0%;
    z-index: 97;
    transform: translateY(-100%);
    opacity: 0;
    transition: all .2s linear
  }
  nav li a:after {
    content: attr(data-content);
    font-size: 8px;
    opacity: 0;
    position: absolute;
    z-index: 97;
    color: #FFFFFF;
    display: block;
    margin-right: auto;
    margin-left: auto;
    left: 0;
    right: 0;
    bottom: -40px;
    visibility: hidden;
    text-transform: none;
    font-family: 'Cormorant Garamond', serif;
    font-weight: 300;
    font-style: italic;
    letter-spacing: 0.3;
    transition: all .2s linear;
  }
  .nav_layer {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    background: #121e2c;
    transform: translateX(-100%)
  }
  .layer_down {
    transition: all .4s ease-in-out;
    transform: translateY(0)
  }
  .layer_up {
    transition: all .8s ease-in-out;
    transform: translateY(-100%)
  }
}
@media(min-width:769px)and(max-width:1035px) {
  nav ul li a {
    font-size: 16px;
    color: #FFF !important;
  }
  nav ul li a:before {
    width: 50px
  }
  nav ul li a:after {
    font-size: 8px
  }
}
@media(min-width:1024px) {
  nav ul li a {
    font-size: 16px;
    color: #FFF !important;
  }
  nav ul li a:before {
    width: 20px
  }
  nav ul li a:after {
    font-size: 12px
  }
}
nav ul li a:hover:before {
  transform: translateY(20%);
  opacity: 1;
}
nav ul li a:hover:after {
  transform: translateY(15px);
  opacity: 1;
  visibility: visible;
}
#item_1 {
  background: #1b2936
}
#item_2 {
  background: #1f303f
}
#item_3 {
  background: #2a3f53
}
#item_4 {
  background: #314b63
}
#item_5 {
  background: #34526d
}
#item_6 {
  background: #345775
}
.anim_out_item_1 {
  -webkit-animation: anim_out_item 0.4s linear 1 .2s both;
          animation: anim_out_item 0.4s linear 1 .2s both
}
.anim_out_item_2 {
  -webkit-animation: anim_out_item 0.4s linear 1 .4s both;
          animation: anim_out_item 0.4s linear 1 .4s both
}
.anim_out_item_3 {
  -webkit-animation: anim_out_item 0.4s linear 1 .6s both;
          animation: anim_out_item 0.4s linear 1 .6s both
}
.anim_out_item_4 {
  -webkit-animation: anim_out_item 0.4s linear 1 .8s both;
          animation: anim_out_item 0.4s linear 1 .8s both
}
.anim_out_item_5 {
  -webkit-animation: anim_out_item 0.4s linear 1 1s both;
          animation: anim_out_item 0.4s linear 1 1s both
}
.anim_out_item_6 {
  -webkit-animation: anim_out_item 0.4s linear 1 1.2s both;
          animation: anim_out_item 0.4s linear 1 1.2s both
}
.anim_item_1 {
  -webkit-animation: anim_item .3s linear 1 .6s both;
          animation: anim_item .3s linear 1 .6s both
}
.anim_item_2 {
  -webkit-animation: anim_item .3s linear 1 .5s both;
          animation: anim_item .3s linear 1 .5s both
}
.anim_item_3 {
  -webkit-animation: anim_item .3s linear 1 .4s both;
          animation: anim_item .3s linear 1 .4s both
}
.anim_item_4 {
  -webkit-animation: anim_item .3s linear 1 .3s both;
          animation: anim_item .3s linear 1 .3s both
}
.anim_item_5 {
  -webkit-animation: anim_item .3s linear 1 .2s both;
          animation: anim_item .3s linear 1 .2s both
}
.anim_item_6 {
  -webkit-animation: anim_item .3s linear 1 both;
          animation: anim_item .3s linear 1 both
}

/* NAV ANIMATION */
@media(max-width:1023px) {
  @-webkit-keyframes anim_out_item {
    from {
      transform: translateX(-100%)
    }
    to {
      transform: translateX(0)
    }
  }
  @keyframes anim_out_item {
    from {
      transform: translateX(-100%)
    }
    to {
      transform: translateX(0)
    }
  }
  @-webkit-keyframes anim_item {
    from {
      transform: translateX(0)
    }
    to {
      transform: translateX(-100%)
    }
  }
  @keyframes anim_item {
    from {
      transform: translateX(0)
    }
    to {
      transform: translateX(-100%)
    }
  }
}
@media(min-width:1024px) {
  @-webkit-keyframes anim_out_item {
    from {
      transform: translateY(-100%)
    }
    to {
      transform: translateY(0)
    }
  }
  @keyframes anim_out_item {
    from {
      transform: translateY(-100%)
    }
    to {
      transform: translateY(0)
    }
  }
  @-webkit-keyframes anim_item {
    from {
      transform: translateY(0)
    }
    to {
      transform: translateY(-100%)
    }
  }
  @keyframes anim_item {
    from {
      transform: translateY(0)
    }
    to {
      transform: translateY(-100%)
    }
  }
}

/* HUMBURGER */
#humburger {
  width: 44px;
  position: fixed;
  top: 50px;
  right: 40px;
  z-index: 100;
  cursor: pointer;
  transition: all .3s ease;
}
#humburger div {
  height: 2px;
  background: #fff;
  cursor: pointer;
  /* custom cursor */
  margin-top: 8px;
  transition-duration: 0.3s;
}
#humburger:hover div {
  background: #fff;
}
@media(max-width: 991.98px) {
  #humburger {
    top: 30px;
    right: 30px;
  }
}
.anim_bar_1 {
  margin-left: 14px;
  -webkit-animation: anim_bar_1 .6s linear 1 both;
          animation: anim_bar_1 .6s linear 1 both
}
.anim_bar_2 {
  margin-left: 14px;
  -webkit-animation: anim_bar_2 .6s linear 1 both;
          animation: anim_bar_2 .6s linear 1 both
}
.anim_bar_3 {
  margin-left: 14px;
  -webkit-animation: anim_bar_3 .6s linear 1 both;
          animation: anim_bar_3 .6s linear 1 both
}
.anim_out_bar_1 {
  -webkit-animation: anim_out_bar_1 .6s linear 1 both;
          animation: anim_out_bar_1 .6s linear 1 both
}
.anim_out_bar_2 {
  -webkit-animation: anim_out_bar_2 .6s linear 1 both;
          animation: anim_out_bar_2 .6s linear 1 both
}
.anim_out_bar_3 {
  -webkit-animation: anim_out_bar_3 .6s linear 1 both;
          animation: anim_out_bar_3 .6s linear 1 both
}
@-webkit-keyframes anim_bar_1 {
  0% {
    background: #2F2F2F;
  }
  80% {
    transform: rotate(405deg) translateY(14px) scale(1.1);
    -webkit-transform: rotate(405deg) translateY(14px) scale(1.1);
    background-color: #FFFFFF
  }
  100% {
    transform: rotate(405deg) translateY(14px) scale(1.2);
    -webkit-transform: rotate(405deg) translateY(14px) scale(1.2);
    background-color: #FFFFFF;
  }
}
@keyframes anim_bar_1 {
  0% {
    background: #2F2F2F;
  }
  80% {
    transform: rotate(405deg) translateY(14px) scale(1.1);
    -webkit-transform: rotate(405deg) translateY(14px) scale(1.1);
    background-color: #FFFFFF
  }
  100% {
    transform: rotate(405deg) translateY(14px) scale(1.2);
    -webkit-transform: rotate(405deg) translateY(14px) scale(1.2);
    background-color: #FFFFFF;
  }
}
@-webkit-keyframes anim_bar_2 {
  0% {
    background: #2F2F2F
  }
  20% {
    background: transparent
  }
  100% {
    background-color: transparent
  }
}
@keyframes anim_bar_2 {
  0% {
    background: #2F2F2F
  }
  20% {
    background: transparent
  }
  100% {
    background-color: transparent
  }
}
@-webkit-keyframes anim_bar_3 {
  0% {
    background: #2F2F2F;
  }
  80% {
    transform: rotate(-405deg) translateY(-14px) scale(1.1);
    -webkit-transform: rotate(-405deg) translateY(-14px) scale(1.1);
  }
  100% {
    transform: rotate(-405deg) translateY(-14px) scale(1.2);
    -webkit-transform: rotate(-405deg) translateY(-14px) scale(1.2);
    background-color: #FFFFFF;
  }
}
@keyframes anim_bar_3 {
  0% {
    background: #2F2F2F;
  }
  80% {
    transform: rotate(-405deg) translateY(-14px) scale(1.1);
    -webkit-transform: rotate(-405deg) translateY(-14px) scale(1.1);
  }
  100% {
    transform: rotate(-405deg) translateY(-14px) scale(1.2);
    -webkit-transform: rotate(-405deg) translateY(-14px) scale(1.2);
    background-color: #FFFFFF;
  }
}
@-webkit-keyframes anim_out_bar_1 {
  0% {
    transform: rotate(405deg) scale(.8);
    -webkit-transform: rotate(405deg) scale(.8);
    background: #FFFFFF;
  }
  80% {
    transform: rotate(5deg) scale(.9);
    -webkit-transform: rotate(5deg) scale(.9);
  }
  100% {
    transform: rotate(0deg) scale(1);
    -webkit-transform: rotate(0deg) scale(1);
    background: #fff;
  }
}
@keyframes anim_out_bar_1 {
  0% {
    transform: rotate(405deg) scale(.8);
    -webkit-transform: rotate(405deg) scale(.8);
    background: #FFFFFF;
  }
  80% {
    transform: rotate(5deg) scale(.9);
    -webkit-transform: rotate(5deg) scale(.9);
  }
  100% {
    transform: rotate(0deg) scale(1);
    -webkit-transform: rotate(0deg) scale(1);
    background: #fff;
  }
}
@-webkit-keyframes anim_out_bar_3 {
  0% {
    transform: rotate(-405deg) scale(.8);
    -webkit-transform: rotate(-405deg) scale(.8);
    background: #FFFFFF
  }
  80% {
    transform: rotate(-5deg) scale(.9);
    -webkit-transform: rotate(-5deg) scale(.9);
    background: #FFFFFF
  }
  100% {
    transform: rotate(0deg) scale(1);
    -webkit-transform: rotate(0deg) scale(1);
    background: #fff;
  }
}
@keyframes anim_out_bar_3 {
  0% {
    transform: rotate(-405deg) scale(.8);
    -webkit-transform: rotate(-405deg) scale(.8);
    background: #FFFFFF
  }
  80% {
    transform: rotate(-5deg) scale(.9);
    -webkit-transform: rotate(-5deg) scale(.9);
    background: #FFFFFF
  }
  100% {
    transform: rotate(0deg) scale(1);
    -webkit-transform: rotate(0deg) scale(1);
    background: #fff;
  }
}
@-webkit-keyframes anim_out_bar_2 {
  0% {
    background: transparent
  }
  60% {
    background: transparent
  }
  100% {
    background: #fff
  }
}
@keyframes anim_out_bar_2 {
  0% {
    background: transparent
  }
  60% {
    background: transparent
  }
  100% {
    background: #fff
  }
}
/* --------------------------------- */

/* SECTION TITLE */
.se_title h3 {
  color: var(--co-1);
  font-weight: 600;
  font-family: 'GT-Walsheim', 'Poppins', sans-serif;
}
.se_title h2 {
  color: #fff;
  font-weight: 900;
  font-size: 56px;
  font-family: 'GT-Walsheim', 'Poppins', sans-serif;
}
.se_title h4 {
  color: #fff;
  font-weight: 900;
  font-size: 34px;
  font-family: 'GT-Walsheim', 'Poppins', sans-serif;
}
.se_title h5 {
  color: #999;
  font-size: 17px;
  font-weight: 300;
  font-family: 'GT-Walsheim', 'Poppins', sans-serif;
}
@media(max-width: 1199.98px) {
  .se_title h2 {
    font-size: 48px;
  }
}
@media(max-width: 991.98px) {
  .se_title h3 {
    font-size: 20px;
  }
  .se_title h2 {
    font-size: 42px;
  }
}
@media(max-width: 767.98px) {
  .se_title {
    text-align: center;
  }
  .se_title h2 {
    font-size: 32px;
  }
}
/* --------------------------------- */

/* CUSTOM BOOTSTRAP CLASSES */

.m-0 {
  margin: 0!important
}

.mt-0, .my-0 {
  margin-top: 0!important
}

.mr-0, .mx-0 {
  margin-right: 0!important
}

.mb-0, .my-0 {
  margin-bottom: 0!important
}

.ml-0, .mx-0 {
  margin-left: 0!important
}

.m-1 {
  margin: 15px!important
}

.mt-1, .my-1 {
  margin-top: 15px!important
}

.mr-1, .mx-1 {
  margin-right: 15px!important
}

.mb-1, .my-1 {
  margin-bottom: 15px!important
}

.ml-1, .mx-1 {
  margin-left: 15px!important
}

.m-2 {
  margin: 30px!important
}

.mt-2, .my-2 {
  margin-top: 30px!important
}

.mr-2, .mx-2 {
  margin-right: 30px!important
}

.mb-2, .my-2 {
  margin-bottom: 30px!important
}

.ml-2, .mx-2 {
  margin-left: 30px!important
}

.m-3 {
  margin: 45px!important
}

.mt-3, .my-3 {
  margin-top: 45px!important
}

.mr-3, .mx-3 {
  margin-right: 45px!important
}

.mb-3, .my-3 {
  margin-bottom: 45px!important
}

.ml-3, .mx-3 {
  margin-left: 45px!important
}

.m-4 {
  margin: 60px!important
}

.mt-4, .my-4 {
  margin-top: 60px!important
}

.mr-4, .mx-4 {
  margin-right: 60px!important
}

.mb-4, .my-4 {
  margin-bottom: 60px!important
}

.ml-4, .mx-4 {
  margin-left: 60px!important
}

.m-5 {
  margin: 75px!important
}

.mt-5, .my-5 {
  margin-top: 75px!important
}

.mr-5, .mx-5 {
  margin-right: 75px!important
}

.mb-5, .my-5 {
  margin-bottom: 75px!important
}

.ml-5, .mx-5 {
  margin-left: 75px!important
}

.p-0 {
  padding: 0!important
}

.pt-0, .py-0 {
  padding-top: 0!important
}

.pr-0, .px-0 {
  padding-right: 0!important
}

.pb-0, .py-0 {
  padding-bottom: 0!important
}

.pl-0, .px-0 {
  padding-left: 0!important
}

.p-1 {
  padding: 15px!important
}

.pt-1, .py-1 {
  padding-top: 15px!important
}

.pr-1, .px-1 {
  padding-right: 15px!important
}

.pb-1, .py-1 {
  padding-bottom: 15px!important
}

.pl-1, .px-1 {
  padding-left: 15px!important
}

.p-2 {
  padding: 30px!important
}

.pt-2, .py-2 {
  padding-top: 30px!important
}

.pr-2, .px-2 {
  padding-right: 30px!important
}

.pb-2, .py-2 {
  padding-bottom: 30px!important
}

.pl-2, .px-2 {
  padding-left: 30px!important
}

.p-3 {
  padding: 45px!important
}

.pt-3, .py-3 {
  padding-top: 45px!important
}

.pr-3, .px-3 {
  padding-right: 45px!important
}

.pb-3, .py-3 {
  padding-bottom: 45px!important
}

.pl-3, .px-3 {
  padding-left: 45px!important
}

.p-4 {
  padding: 60px!important
}

.pt-4, .py-4 {
  padding-top: 60px!important
}

.pr-4, .px-4 {
  padding-right: 60px!important
}

.pb-4, .py-4 {
  padding-bottom: 60px!important
}

.pl-4, .px-4 {
  padding-left: 60px!important
}

.p-5 {
  padding: 75px!important
}

.pt-5, .py-5 {
  padding-top: 75px!important
}

.pr-5, .px-5 {
  padding-right: 75px!important
}

.pb-5, .py-5 {
  padding-bottom: 75px!important
}

.pl-5, .px-5 {
  padding-left: 75px!important
}

.m-n1 {
  margin: -15px!important
}

.mt-n1, .my-n1 {
  margin-top: -15px!important
}

.mr-n1, .mx-n1 {
  margin-right: -15px!important
}

.mb-n1, .my-n1 {
  margin-bottom: -15px!important
}

.ml-n1, .mx-n1 {
  margin-left: -15px!important
}

.m-n2 {
  margin: -30px!important
}

.mt-n2, .my-n2 {
  margin-top: -30px!important
}

.mr-n2, .mx-n2 {
  margin-right: -30px!important
}

.mb-n2, .my-n2 {
  margin-bottom: -30px!important
}

.ml-n2, .mx-n2 {
  margin-left: -30px!important
}

.m-n3 {
  margin: -45px!important
}

.mt-n3, .my-n3 {
  margin-top: -45px!important
}

.mr-n3, .mx-n3 {
  margin-right: -45px!important
}

.mb-n3, .my-n3 {
  margin-bottom: -45px!important
}

.ml-n3, .mx-n3 {
  margin-left: -45px!important
}

.m-n4 {
  margin: -60px!important
}

.mt-n4, .my-n4 {
  margin-top: -60px!important
}

.mr-n4, .mx-n4 {
  margin-right: -60px!important
}

.mb-n4, .my-n4 {
  margin-bottom: -60px!important
}

.ml-n4, .mx-n4 {
  margin-left: -60px!important
}

.m-n5 {
  margin: -75px!important
}

.mt-n5, .my-n5 {
  margin-top: -75px!important
}

.mr-n5, .mx-n5 {
  margin-right: -75px!important
}

.mb-n5, .my-n5 {
  margin-bottom: -75px!important
}

.ml-n5, .mx-n5 {
  margin-left: -75px!important
}

.m-auto {
  margin: auto!important
}

.mt-auto, .my-auto {
  margin-top: auto!important
}

.mr-auto, .mx-auto {
  margin-right: auto!important
}

.mb-auto, .my-auto {
  margin-bottom: auto!important
}

.ml-auto, .mx-auto {
  margin-left: auto!important
}

@media (min-width:576px) {
  .m-sm-0 {
    margin: 0!important
  }
  .mt-sm-0, .my-sm-0 {
    margin-top: 0!important
  }
  .mr-sm-0, .mx-sm-0 {
    margin-right: 0!important
  }
  .mb-sm-0, .my-sm-0 {
    margin-bottom: 0!important
  }
  .ml-sm-0, .mx-sm-0 {
    margin-left: 0!important
  }
  .m-sm-1 {
    margin: 15px!important
  }
  .mt-sm-1, .my-sm-1 {
    margin-top: 15px!important
  }
  .mr-sm-1, .mx-sm-1 {
    margin-right: 15px!important
  }
  .mb-sm-1, .my-sm-1 {
    margin-bottom: 15px!important
  }
  .ml-sm-1, .mx-sm-1 {
    margin-left: 15px!important
  }
  .m-sm-2 {
    margin: 30px!important
  }
  .mt-sm-2, .my-sm-2 {
    margin-top: 30px!important
  }
  .mr-sm-2, .mx-sm-2 {
    margin-right: 30px!important
  }
  .mb-sm-2, .my-sm-2 {
    margin-bottom: 30px!important
  }
  .ml-sm-2, .mx-sm-2 {
    margin-left: 30px!important
  }
  .m-sm-3 {
    margin: 45px!important
  }
  .mt-sm-3, .my-sm-3 {
    margin-top: 45px!important
  }
  .mr-sm-3, .mx-sm-3 {
    margin-right: 45px!important
  }
  .mb-sm-3, .my-sm-3 {
    margin-bottom: 45px!important
  }
  .ml-sm-3, .mx-sm-3 {
    margin-left: 45px!important
  }
  .m-sm-4 {
    margin: 60px!important
  }
  .mt-sm-4, .my-sm-4 {
    margin-top: 60px!important
  }
  .mr-sm-4, .mx-sm-4 {
    margin-right: 60px!important
  }
  .mb-sm-4, .my-sm-4 {
    margin-bottom: 60px!important
  }
  .ml-sm-4, .mx-sm-4 {
    margin-left: 60px!important
  }
  .m-sm-5 {
    margin: 75px!important
  }
  .mt-sm-5, .my-sm-5 {
    margin-top: 75px!important
  }
  .mr-sm-5, .mx-sm-5 {
    margin-right: 75px!important
  }
  .mb-sm-5, .my-sm-5 {
    margin-bottom: 75px!important
  }
  .ml-sm-5, .mx-sm-5 {
    margin-left: 75px!important
  }
  .p-sm-0 {
    padding: 0!important
  }
  .pt-sm-0, .py-sm-0 {
    padding-top: 0!important
  }
  .pr-sm-0, .px-sm-0 {
    padding-right: 0!important
  }
  .pb-sm-0, .py-sm-0 {
    padding-bottom: 0!important
  }
  .pl-sm-0, .px-sm-0 {
    padding-left: 0!important
  }
  .p-sm-1 {
    padding: 15px!important
  }
  .pt-sm-1, .py-sm-1 {
    padding-top: 15px!important
  }
  .pr-sm-1, .px-sm-1 {
    padding-right: 15px!important
  }
  .pb-sm-1, .py-sm-1 {
    padding-bottom: 15px!important
  }
  .pl-sm-1, .px-sm-1 {
    padding-left: 15px!important
  }
  .p-sm-2 {
    padding: 30px!important
  }
  .pt-sm-2, .py-sm-2 {
    padding-top: 30px!important
  }
  .pr-sm-2, .px-sm-2 {
    padding-right: 30px!important
  }
  .pb-sm-2, .py-sm-2 {
    padding-bottom: 30px!important
  }
  .pl-sm-2, .px-sm-2 {
    padding-left: 30px!important
  }
  .p-sm-3 {
    padding: 45px!important
  }
  .pt-sm-3, .py-sm-3 {
    padding-top: 45px!important
  }
  .pr-sm-3, .px-sm-3 {
    padding-right: 45px!important
  }
  .pb-sm-3, .py-sm-3 {
    padding-bottom: 45px!important
  }
  .pl-sm-3, .px-sm-3 {
    padding-left: 45px!important
  }
  .p-sm-4 {
    padding: 60px!important
  }
  .pt-sm-4, .py-sm-4 {
    padding-top: 60px!important
  }
  .pr-sm-4, .px-sm-4 {
    padding-right: 60px!important
  }
  .pb-sm-4, .py-sm-4 {
    padding-bottom: 60px!important
  }
  .pl-sm-4, .px-sm-4 {
    padding-left: 60px!important
  }
  .p-sm-5 {
    padding: 75px!important
  }
  .pt-sm-5, .py-sm-5 {
    padding-top: 75px!important
  }
  .pr-sm-5, .px-sm-5 {
    padding-right: 75px!important
  }
  .pb-sm-5, .py-sm-5 {
    padding-bottom: 75px!important
  }
  .pl-sm-5, .px-sm-5 {
    padding-left: 75px!important
  }
  .m-sm-n1 {
    margin: -15px!important
  }
  .mt-sm-n1, .my-sm-n1 {
    margin-top: -15px!important
  }
  .mr-sm-n1, .mx-sm-n1 {
    margin-right: -15px!important
  }
  .mb-sm-n1, .my-sm-n1 {
    margin-bottom: -15px!important
  }
  .ml-sm-n1, .mx-sm-n1 {
    margin-left: -15px!important
  }
  .m-sm-n2 {
    margin: -30px!important
  }
  .mt-sm-n2, .my-sm-n2 {
    margin-top: -30px!important
  }
  .mr-sm-n2, .mx-sm-n2 {
    margin-right: -30px!important
  }
  .mb-sm-n2, .my-sm-n2 {
    margin-bottom: -30px!important
  }
  .ml-sm-n2, .mx-sm-n2 {
    margin-left: -30px!important
  }
  .m-sm-n3 {
    margin: -45px!important
  }
  .mt-sm-n3, .my-sm-n3 {
    margin-top: -45px!important
  }
  .mr-sm-n3, .mx-sm-n3 {
    margin-right: -45px!important
  }
  .mb-sm-n3, .my-sm-n3 {
    margin-bottom: -45px!important
  }
  .ml-sm-n3, .mx-sm-n3 {
    margin-left: -45px!important
  }
  .m-sm-n4 {
    margin: -60px!important
  }
  .mt-sm-n4, .my-sm-n4 {
    margin-top: -60px!important
  }
  .mr-sm-n4, .mx-sm-n4 {
    margin-right: -60px!important
  }
  .mb-sm-n4, .my-sm-n4 {
    margin-bottom: -60px!important
  }
  .ml-sm-n4, .mx-sm-n4 {
    margin-left: -60px!important
  }
  .m-sm-n5 {
    margin: -75px!important
  }
  .mt-sm-n5, .my-sm-n5 {
    margin-top: -75px!important
  }
  .mr-sm-n5, .mx-sm-n5 {
    margin-right: -75px!important
  }
  .mb-sm-n5, .my-sm-n5 {
    margin-bottom: -75px!important
  }
  .ml-sm-n5, .mx-sm-n5 {
    margin-left: -75px!important
  }
  .m-sm-auto {
    margin: auto!important
  }
  .mt-sm-auto, .my-sm-auto {
    margin-top: auto!important
  }
  .mr-sm-auto, .mx-sm-auto {
    margin-right: auto!important
  }
  .mb-sm-auto, .my-sm-auto {
    margin-bottom: auto!important
  }
  .ml-sm-auto, .mx-sm-auto {
    margin-left: auto!important
  }
}

@media (min-width:768px) {
  .m-md-0 {
    margin: 0!important
  }
  .mt-md-0, .my-md-0 {
    margin-top: 0!important
  }
  .mr-md-0, .mx-md-0 {
    margin-right: 0!important
  }
  .mb-md-0, .my-md-0 {
    margin-bottom: 0!important
  }
  .ml-md-0, .mx-md-0 {
    margin-left: 0!important
  }
  .m-md-1 {
    margin: 15px!important
  }
  .mt-md-1, .my-md-1 {
    margin-top: 15px!important
  }
  .mr-md-1, .mx-md-1 {
    margin-right: 15px!important
  }
  .mb-md-1, .my-md-1 {
    margin-bottom: 15px!important
  }
  .ml-md-1, .mx-md-1 {
    margin-left: 15px!important
  }
  .m-md-2 {
    margin: 30px!important
  }
  .mt-md-2, .my-md-2 {
    margin-top: 30px!important
  }
  .mr-md-2, .mx-md-2 {
    margin-right: 30px!important
  }
  .mb-md-2, .my-md-2 {
    margin-bottom: 30px!important
  }
  .ml-md-2, .mx-md-2 {
    margin-left: 30px!important
  }
  .m-md-3 {
    margin: 45px!important
  }
  .mt-md-3, .my-md-3 {
    margin-top: 45px!important
  }
  .mr-md-3, .mx-md-3 {
    margin-right: 45px!important
  }
  .mb-md-3, .my-md-3 {
    margin-bottom: 45px!important
  }
  .ml-md-3, .mx-md-3 {
    margin-left: 45px!important
  }
  .m-md-4 {
    margin: 60px!important
  }
  .mt-md-4, .my-md-4 {
    margin-top: 60px!important
  }
  .mr-md-4, .mx-md-4 {
    margin-right: 60px!important
  }
  .mb-md-4, .my-md-4 {
    margin-bottom: 60px!important
  }
  .ml-md-4, .mx-md-4 {
    margin-left: 60px!important
  }
  .m-md-5 {
    margin: 75px!important
  }
  .mt-md-5, .my-md-5 {
    margin-top: 75px!important
  }
  .mr-md-5, .mx-md-5 {
    margin-right: 75px!important
  }
  .mb-md-5, .my-md-5 {
    margin-bottom: 75px!important
  }
  .ml-md-5, .mx-md-5 {
    margin-left: 75px!important
  }
  .p-md-0 {
    padding: 0!important
  }
  .pt-md-0, .py-md-0 {
    padding-top: 0!important
  }
  .pr-md-0, .px-md-0 {
    padding-right: 0!important
  }
  .pb-md-0, .py-md-0 {
    padding-bottom: 0!important
  }
  .pl-md-0, .px-md-0 {
    padding-left: 0!important
  }
  .p-md-1 {
    padding: 15px!important
  }
  .pt-md-1, .py-md-1 {
    padding-top: 15px!important
  }
  .pr-md-1, .px-md-1 {
    padding-right: 15px!important
  }
  .pb-md-1, .py-md-1 {
    padding-bottom: 15px!important
  }
  .pl-md-1, .px-md-1 {
    padding-left: 15px!important
  }
  .p-md-2 {
    padding: 30px!important
  }
  .pt-md-2, .py-md-2 {
    padding-top: 30px!important
  }
  .pr-md-2, .px-md-2 {
    padding-right: 30px!important
  }
  .pb-md-2, .py-md-2 {
    padding-bottom: 30px!important
  }
  .pl-md-2, .px-md-2 {
    padding-left: 30px!important
  }
  .p-md-3 {
    padding: 45px!important
  }
  .pt-md-3, .py-md-3 {
    padding-top: 45px!important
  }
  .pr-md-3, .px-md-3 {
    padding-right: 45px!important
  }
  .pb-md-3, .py-md-3 {
    padding-bottom: 45px!important
  }
  .pl-md-3, .px-md-3 {
    padding-left: 45px!important
  }
  .p-md-4 {
    padding: 60px!important
  }
  .pt-md-4, .py-md-4 {
    padding-top: 60px!important
  }
  .pr-md-4, .px-md-4 {
    padding-right: 60px!important
  }
  .pb-md-4, .py-md-4 {
    padding-bottom: 60px!important
  }
  .pl-md-4, .px-md-4 {
    padding-left: 60px!important
  }
  .p-md-5 {
    padding: 75px!important
  }
  .pt-md-5, .py-md-5 {
    padding-top: 75px!important
  }
  .pr-md-5, .px-md-5 {
    padding-right: 75px!important
  }
  .pb-md-5, .py-md-5 {
    padding-bottom: 75px!important
  }
  .pl-md-5, .px-md-5 {
    padding-left: 75px!important
  }
  .m-md-n1 {
    margin: -15px!important
  }
  .mt-md-n1, .my-md-n1 {
    margin-top: -15px!important
  }
  .mr-md-n1, .mx-md-n1 {
    margin-right: -15px!important
  }
  .mb-md-n1, .my-md-n1 {
    margin-bottom: -15px!important
  }
  .ml-md-n1, .mx-md-n1 {
    margin-left: -15px!important
  }
  .m-md-n2 {
    margin: -30px!important
  }
  .mt-md-n2, .my-md-n2 {
    margin-top: -30px!important
  }
  .mr-md-n2, .mx-md-n2 {
    margin-right: -30px!important
  }
  .mb-md-n2, .my-md-n2 {
    margin-bottom: -30px!important
  }
  .ml-md-n2, .mx-md-n2 {
    margin-left: -30px!important
  }
  .m-md-n3 {
    margin: -45px!important
  }
  .mt-md-n3, .my-md-n3 {
    margin-top: -45px!important
  }
  .mr-md-n3, .mx-md-n3 {
    margin-right: -45px!important
  }
  .mb-md-n3, .my-md-n3 {
    margin-bottom: -45px!important
  }
  .ml-md-n3, .mx-md-n3 {
    margin-left: -45px!important
  }
  .m-md-n4 {
    margin: -60px!important
  }
  .mt-md-n4, .my-md-n4 {
    margin-top: -60px!important
  }
  .mr-md-n4, .mx-md-n4 {
    margin-right: -60px!important
  }
  .mb-md-n4, .my-md-n4 {
    margin-bottom: -60px!important
  }
  .ml-md-n4, .mx-md-n4 {
    margin-left: -60px!important
  }
  .m-md-n5 {
    margin: -75px!important
  }
  .mt-md-n5, .my-md-n5 {
    margin-top: -75px!important
  }
  .mr-md-n5, .mx-md-n5 {
    margin-right: -75px!important
  }
  .mb-md-n5, .my-md-n5 {
    margin-bottom: -75px!important
  }
  .ml-md-n5, .mx-md-n5 {
    margin-left: -75px!important
  }
  .m-md-auto {
    margin: auto!important
  }
  .mt-md-auto, .my-md-auto {
    margin-top: auto!important
  }
  .mr-md-auto, .mx-md-auto {
    margin-right: auto!important
  }
  .mb-md-auto, .my-md-auto {
    margin-bottom: auto!important
  }
  .ml-md-auto, .mx-md-auto {
    margin-left: auto!important
  }
}

@media (min-width:992px) {
  .m-lg-0 {
    margin: 0!important
  }
  .mt-lg-0, .my-lg-0 {
    margin-top: 0!important
  }
  .mr-lg-0, .mx-lg-0 {
    margin-right: 0!important
  }
  .mb-lg-0, .my-lg-0 {
    margin-bottom: 0!important
  }
  .ml-lg-0, .mx-lg-0 {
    margin-left: 0!important
  }
  .m-lg-1 {
    margin: 15px!important
  }
  .mt-lg-1, .my-lg-1 {
    margin-top: 15px!important
  }
  .mr-lg-1, .mx-lg-1 {
    margin-right: 15px!important
  }
  .mb-lg-1, .my-lg-1 {
    margin-bottom: 15px!important
  }
  .ml-lg-1, .mx-lg-1 {
    margin-left: 15px!important
  }
  .m-lg-2 {
    margin: 30px!important
  }
  .mt-lg-2, .my-lg-2 {
    margin-top: 30px!important
  }
  .mr-lg-2, .mx-lg-2 {
    margin-right: 30px!important
  }
  .mb-lg-2, .my-lg-2 {
    margin-bottom: 30px!important
  }
  .ml-lg-2, .mx-lg-2 {
    margin-left: 30px!important
  }
  .m-lg-3 {
    margin: 45px!important
  }
  .mt-lg-3, .my-lg-3 {
    margin-top: 45px!important
  }
  .mr-lg-3, .mx-lg-3 {
    margin-right: 45px!important
  }
  .mb-lg-3, .my-lg-3 {
    margin-bottom: 45px!important
  }
  .ml-lg-3, .mx-lg-3 {
    margin-left: 45px!important
  }
  .m-lg-4 {
    margin: 60px!important
  }
  .mt-lg-4, .my-lg-4 {
    margin-top: 60px!important
  }
  .mr-lg-4, .mx-lg-4 {
    margin-right: 60px!important
  }
  .mb-lg-4, .my-lg-4 {
    margin-bottom: 60px!important
  }
  .ml-lg-4, .mx-lg-4 {
    margin-left: 60px!important
  }
  .m-lg-5 {
    margin: 75px!important
  }
  .mt-lg-5, .my-lg-5 {
    margin-top: 75px!important
  }
  .mr-lg-5, .mx-lg-5 {
    margin-right: 75px!important
  }
  .mb-lg-5, .my-lg-5 {
    margin-bottom: 75px!important
  }
  .ml-lg-5, .mx-lg-5 {
    margin-left: 75px!important
  }
  .p-lg-0 {
    padding: 0!important
  }
  .pt-lg-0, .py-lg-0 {
    padding-top: 0!important
  }
  .pr-lg-0, .px-lg-0 {
    padding-right: 0!important
  }
  .pb-lg-0, .py-lg-0 {
    padding-bottom: 0!important
  }
  .pl-lg-0, .px-lg-0 {
    padding-left: 0!important
  }
  .p-lg-1 {
    padding: 15px!important
  }
  .pt-lg-1, .py-lg-1 {
    padding-top: 15px!important
  }
  .pr-lg-1, .px-lg-1 {
    padding-right: 15px!important
  }
  .pb-lg-1, .py-lg-1 {
    padding-bottom: 15px!important
  }
  .pl-lg-1, .px-lg-1 {
    padding-left: 15px!important
  }
  .p-lg-2 {
    padding: 30px!important
  }
  .pt-lg-2, .py-lg-2 {
    padding-top: 30px!important
  }
  .pr-lg-2, .px-lg-2 {
    padding-right: 30px!important
  }
  .pb-lg-2, .py-lg-2 {
    padding-bottom: 30px!important
  }
  .pl-lg-2, .px-lg-2 {
    padding-left: 30px!important
  }
  .p-lg-3 {
    padding: 45px!important
  }
  .pt-lg-3, .py-lg-3 {
    padding-top: 45px!important
  }
  .pr-lg-3, .px-lg-3 {
    padding-right: 45px!important
  }
  .pb-lg-3, .py-lg-3 {
    padding-bottom: 45px!important
  }
  .pl-lg-3, .px-lg-3 {
    padding-left: 45px!important
  }
  .p-lg-4 {
    padding: 60px!important
  }
  .pt-lg-4, .py-lg-4 {
    padding-top: 60px!important
  }
  .pr-lg-4, .px-lg-4 {
    padding-right: 60px!important
  }
  .pb-lg-4, .py-lg-4 {
    padding-bottom: 60px!important
  }
  .pl-lg-4, .px-lg-4 {
    padding-left: 60px!important
  }
  .p-lg-5 {
    padding: 75px!important
  }
  .pt-lg-5, .py-lg-5 {
    padding-top: 75px!important
  }
  .pr-lg-5, .px-lg-5 {
    padding-right: 75px!important
  }
  .pb-lg-5, .py-lg-5 {
    padding-bottom: 75px!important
  }
  .pl-lg-5, .px-lg-5 {
    padding-left: 75px!important
  }
  .m-lg-n1 {
    margin: -15px!important
  }
  .mt-lg-n1, .my-lg-n1 {
    margin-top: -15px!important
  }
  .mr-lg-n1, .mx-lg-n1 {
    margin-right: -15px!important
  }
  .mb-lg-n1, .my-lg-n1 {
    margin-bottom: -15px!important
  }
  .ml-lg-n1, .mx-lg-n1 {
    margin-left: -15px!important
  }
  .m-lg-n2 {
    margin: -30px!important
  }
  .mt-lg-n2, .my-lg-n2 {
    margin-top: -30px!important
  }
  .mr-lg-n2, .mx-lg-n2 {
    margin-right: -30px!important
  }
  .mb-lg-n2, .my-lg-n2 {
    margin-bottom: -30px!important
  }
  .ml-lg-n2, .mx-lg-n2 {
    margin-left: -30px!important
  }
  .m-lg-n3 {
    margin: -45px!important
  }
  .mt-lg-n3, .my-lg-n3 {
    margin-top: -45px!important
  }
  .mr-lg-n3, .mx-lg-n3 {
    margin-right: -45px!important
  }
  .mb-lg-n3, .my-lg-n3 {
    margin-bottom: -45px!important
  }
  .ml-lg-n3, .mx-lg-n3 {
    margin-left: -45px!important
  }
  .m-lg-n4 {
    margin: -60px!important
  }
  .mt-lg-n4, .my-lg-n4 {
    margin-top: -60px!important
  }
  .mr-lg-n4, .mx-lg-n4 {
    margin-right: -60px!important
  }
  .mb-lg-n4, .my-lg-n4 {
    margin-bottom: -60px!important
  }
  .ml-lg-n4, .mx-lg-n4 {
    margin-left: -60px!important
  }
  .m-lg-n5 {
    margin: -75px!important
  }
  .mt-lg-n5, .my-lg-n5 {
    margin-top: -75px!important
  }
  .mr-lg-n5, .mx-lg-n5 {
    margin-right: -75px!important
  }
  .mb-lg-n5, .my-lg-n5 {
    margin-bottom: -75px!important
  }
  .ml-lg-n5, .mx-lg-n5 {
    margin-left: -75px!important
  }
  .m-lg-auto {
    margin: auto!important
  }
  .mt-lg-auto, .my-lg-auto {
    margin-top: auto!important
  }
  .mr-lg-auto, .mx-lg-auto {
    margin-right: auto!important
  }
  .mb-lg-auto, .my-lg-auto {
    margin-bottom: auto!important
  }
  .ml-lg-auto, .mx-lg-auto {
    margin-left: auto!important
  }
}

@media (min-width:1200px) {
  .m-xl-0 {
    margin: 0!important
  }
  .mt-xl-0, .my-xl-0 {
    margin-top: 0!important
  }
  .mr-xl-0, .mx-xl-0 {
    margin-right: 0!important
  }
  .mb-xl-0, .my-xl-0 {
    margin-bottom: 0!important
  }
  .ml-xl-0, .mx-xl-0 {
    margin-left: 0!important
  }
  .m-xl-1 {
    margin: 15px!important
  }
  .mt-xl-1, .my-xl-1 {
    margin-top: 15px!important
  }
  .mr-xl-1, .mx-xl-1 {
    margin-right: 15px!important
  }
  .mb-xl-1, .my-xl-1 {
    margin-bottom: 15px!important
  }
  .ml-xl-1, .mx-xl-1 {
    margin-left: 15px!important
  }
  .m-xl-2 {
    margin: 30px!important
  }
  .mt-xl-2, .my-xl-2 {
    margin-top: 30px!important
  }
  .mr-xl-2, .mx-xl-2 {
    margin-right: 30px!important
  }
  .mb-xl-2, .my-xl-2 {
    margin-bottom: 30px!important
  }
  .ml-xl-2, .mx-xl-2 {
    margin-left: 30px!important
  }
  .m-xl-3 {
    margin: 45px!important
  }
  .mt-xl-3, .my-xl-3 {
    margin-top: 45px!important
  }
  .mr-xl-3, .mx-xl-3 {
    margin-right: 45px!important
  }
  .mb-xl-3, .my-xl-3 {
    margin-bottom: 45px!important
  }
  .ml-xl-3, .mx-xl-3 {
    margin-left: 45px!important
  }
  .m-xl-4 {
    margin: 60px!important
  }
  .mt-xl-4, .my-xl-4 {
    margin-top: 60px!important
  }
  .mr-xl-4, .mx-xl-4 {
    margin-right: 60px!important
  }
  .mb-xl-4, .my-xl-4 {
    margin-bottom: 60px!important
  }
  .ml-xl-4, .mx-xl-4 {
    margin-left: 60px!important
  }
  .m-xl-5 {
    margin: 75px!important
  }
  .mt-xl-5, .my-xl-5 {
    margin-top: 75px!important
  }
  .mr-xl-5, .mx-xl-5 {
    margin-right: 75px!important
  }
  .mb-xl-5, .my-xl-5 {
    margin-bottom: 75px!important
  }
  .ml-xl-5, .mx-xl-5 {
    margin-left: 75px!important
  }
  .p-xl-0 {
    padding: 0!important
  }
  .pt-xl-0, .py-xl-0 {
    padding-top: 0!important
  }
  .pr-xl-0, .px-xl-0 {
    padding-right: 0!important
  }
  .pb-xl-0, .py-xl-0 {
    padding-bottom: 0!important
  }
  .pl-xl-0, .px-xl-0 {
    padding-left: 0!important
  }
  .p-xl-1 {
    padding: 15px!important
  }
  .pt-xl-1, .py-xl-1 {
    padding-top: 15px!important
  }
  .pr-xl-1, .px-xl-1 {
    padding-right: 15px!important
  }
  .pb-xl-1, .py-xl-1 {
    padding-bottom: 15px!important
  }
  .pl-xl-1, .px-xl-1 {
    padding-left: 15px!important
  }
  .p-xl-2 {
    padding: 30px!important
  }
  .pt-xl-2, .py-xl-2 {
    padding-top: 30px!important
  }
  .pr-xl-2, .px-xl-2 {
    padding-right: 30px!important
  }
  .pb-xl-2, .py-xl-2 {
    padding-bottom: 30px!important
  }
  .pl-xl-2, .px-xl-2 {
    padding-left: 30px!important
  }
  .p-xl-3 {
    padding: 45px!important
  }
  .pt-xl-3, .py-xl-3 {
    padding-top: 45px!important
  }
  .pr-xl-3, .px-xl-3 {
    padding-right: 45px!important
  }
  .pb-xl-3, .py-xl-3 {
    padding-bottom: 45px!important
  }
  .pl-xl-3, .px-xl-3 {
    padding-left: 45px!important
  }
  .p-xl-4 {
    padding: 60px!important
  }
  .pt-xl-4, .py-xl-4 {
    padding-top: 60px!important
  }
  .pr-xl-4, .px-xl-4 {
    padding-right: 60px!important
  }
  .pb-xl-4, .py-xl-4 {
    padding-bottom: 60px!important
  }
  .pl-xl-4, .px-xl-4 {
    padding-left: 60px!important
  }
  .p-xl-5 {
    padding: 75px!important
  }
  .pt-xl-5, .py-xl-5 {
    padding-top: 75px!important
  }
  .pr-xl-5, .px-xl-5 {
    padding-right: 75px!important
  }
  .pb-xl-5, .py-xl-5 {
    padding-bottom: 75px!important
  }
  .pl-xl-5, .px-xl-5 {
    padding-left: 75px!important
  }
  .m-xl-n1 {
    margin: -15px!important
  }
  .mt-xl-n1, .my-xl-n1 {
    margin-top: -15px!important
  }
  .mr-xl-n1, .mx-xl-n1 {
    margin-right: -15px!important
  }
  .mb-xl-n1, .my-xl-n1 {
    margin-bottom: -15px!important
  }
  .ml-xl-n1, .mx-xl-n1 {
    margin-left: -15px!important
  }
  .m-xl-n2 {
    margin: -30px!important
  }
  .mt-xl-n2, .my-xl-n2 {
    margin-top: -30px!important
  }
  .mr-xl-n2, .mx-xl-n2 {
    margin-right: -30px!important
  }
  .mb-xl-n2, .my-xl-n2 {
    margin-bottom: -30px!important
  }
  .ml-xl-n2, .mx-xl-n2 {
    margin-left: -30px!important
  }
  .m-xl-n3 {
    margin: -45px!important
  }
  .mt-xl-n3, .my-xl-n3 {
    margin-top: -45px!important
  }
  .mr-xl-n3, .mx-xl-n3 {
    margin-right: -45px!important
  }
  .mb-xl-n3, .my-xl-n3 {
    margin-bottom: -45px!important
  }
  .ml-xl-n3, .mx-xl-n3 {
    margin-left: -45px!important
  }
  .m-xl-n4 {
    margin: -60px!important
  }
  .mt-xl-n4, .my-xl-n4 {
    margin-top: -60px!important
  }
  .mr-xl-n4, .mx-xl-n4 {
    margin-right: -60px!important
  }
  .mb-xl-n4, .my-xl-n4 {
    margin-bottom: -60px!important
  }
  .ml-xl-n4, .mx-xl-n4 {
    margin-left: -60px!important
  }
  .m-xl-n5 {
    margin: -75px!important
  }
  .mt-xl-n5, .my-xl-n5 {
    margin-top: -75px!important
  }
  .mr-xl-n5, .mx-xl-n5 {
    margin-right: -75px!important
  }
  .mb-xl-n5, .my-xl-n5 {
    margin-bottom: -75px!important
  }
  .ml-xl-n5, .mx-xl-n5 {
    margin-left: -75px!important
  }
  .m-xl-auto {
    margin: auto!important
  }
  .mt-xl-auto, .my-xl-auto {
    margin-top: auto!important
  }
  .mr-xl-auto, .mx-xl-auto {
    margin-right: auto!important
  }
  .mb-xl-auto, .my-xl-auto {
    margin-bottom: auto!important
  }
  .ml-xl-auto, .mx-xl-auto {
    margin-left: auto!important
  }
}