@import 'npm:bootstrap/dist/css/bootstrap.min.css';
@import 'npm:slick-carousel/slick/slick.css';
@import './FontAwesome.css';
@import './style.scss';

/* BODY */
body {
  background: var(--co-4);
}
/* --------------------------------- */

/* LOADER */
.loader_screen {
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #0a0a0a;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader {
  --path: #0069ff;
  --dot: #5628EE;
  --duration: 3s;
  width: 60px;
  height: 60px;
  position: relative;
  transform: scale(1.5);
}
.loader svg {
  display: block;
  width: 100%;
  height: 100%;
}
.loader svg rect {
  fill: none;
  stroke: var(--path);
  stroke-width: 6px;
  stroke-linejoin: round;
  stroke-linecap: round;
}
.loader svg rect {
  stroke-dasharray: 192 64 192 64;
  stroke-dashoffset: 0;
  -webkit-animation: pathRect 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
          animation: pathRect 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}
.loader span {
  font-size: 50px;
  color: var(--path);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'GT-Walsheim';
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@-webkit-keyframes pathRect {
  25% {
    stroke-dashoffset: 64;
  }
  50% {
    stroke-dashoffset: 128;
  }
  75% {
    stroke-dashoffset: 192;
  }
  100% {
    stroke-dashoffset: 256;
  }
}
@keyframes pathRect {
  25% {
    stroke-dashoffset: 64;
  }
  50% {
    stroke-dashoffset: 128;
  }
  75% {
    stroke-dashoffset: 192;
  }
  100% {
    stroke-dashoffset: 256;
  }
}
/* --------------------------------- */

/* SECTIONS BAR */
.scrollspy_bar {
  position: fixed;
  top: 50px;
  bottom: 50px;
  left: 20px;
  padding: 30px 0;
  width: 90px;
  z-index: 99;
}
.scrollspy_bar::before {
  position: absolute;
  content: '';
  width: 2px;
  background: rgba(255,255,255,.05);
  top: 0;
  bottom: 0;
  left: 30px;
}
.scrollspy_bar li {
  cursor: pointer;
  position: relative;
}
.scrollspy_bar .small_group {
  position: absolute;
}
.scrollspy_bar .small_point {
  position: relative;
  left: 29px;
  width: 4px;
  height: 4px;
  background: rgba(255,255,255,.3);
  border-radius: 50%;
  transition: all .25s;
}
.scrollspy_bar .small_point.active {
  background: var(--co-1);
}
.scrollspy_bar .small_point:not(:first-child) {
  margin-top: 10px;
}
.scrollspy_bar span {
  color: rgba(255,255,255,.3);
  font-size: 16px;
  font-weight: 500;
}
.scrollspy_bar .active span {
  color: #FFF;
}
.scrollspy_bar .num {
  position: relative;
  left: 45px;
  transition: all .25s;
}
.scrollspy_bar .active .num {
  left: 0;
}
.scrollspy_bar .dot {
  position: absolute;
  left: 29px;
  width: 4px;
  height: 4px;
  background: rgba(255,255,255,.3);
  border-radius: 50%;
}
.scrollspy_bar .active .dot {
  background: var(--co-1);
}
.scrollspy_bar .name {
  position: relative;
  visibility: hidden;
  opacity: 0;
  left: 25px;
  white-space: nowrap;
  transition: all .25s;
}
.scrollspy_bar .active .name {
  visibility: visible;
  opacity: 1;
  left: 30px;
}
@media(max-width: 1365.98px) {
  .scrollspy_bar {
    display: none;
  }
}
/* --------------------------------- */

/* MAIN */
// @media(min-width: 1366px) {
//   main {
//     max-width: calc(100% - 50px);
//   }
// }
/* --------------------------------- */

.home-section {
  z-index: 2;
  background-size: cover;

  h1 {
    font-size: 76px;
    color: #FFF;
    font-weight: 900;
    font-family: 'GT-Walsheim', 'Poppins', sans-serif;
    line-height: 1.05;
    text-transform: uppercase;
    text-shadow: 8px 8px 0px #003b7a4d;
    
    .sm {
      font-size: 1.3em;
    }
  }

  .text {
    padding: 150px 0 100px;
  }
}

@media(max-width: 991.98px) {
  .home-section {
    h1 {
      font-size: 50px;

      .sm {
        font-size: 1.1em;
      }
    }
  }
}
/* --------------------------------- */

.text-section {
  border-top: 2px solid #202020;
  border-bottom: 2px solid #202020;
}
.text-section .box h4 {
  font-size: 32px;
  color: #FFF;
  font-weight: 600;

  .dot {
    display: inline-block;
    position: relative;
    width: 20px;

    &:before {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      border-left: 10px solid var(--co-1);
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
    }
  }
}
.text-section .box p {
  font-size: 16px;
  color: #999;
  line-height: 2;
}
@media(max-width: 767.98px) {
  .text-section .box h4 {
    font-size: 26px;
  }
}


.community-section {
  background: var(--primary);
  h3 {
    color: #fff;
  }
  h2 {
    text-shadow: 8px 8px 0px #003b7a4d;
  }
}

.community-section .box {
  padding: 60px 40px 0 40px;
  background: transparent;
}

.community-section .box .icon {
  width: 60px;
}

.community-section .box .title {
  font-size: 24px;
	color: #FFF;
  font-weight: 600;
  font-family: 'GT-Walsheim', 'Poppins', sans-serif;
}
.community-section .box p {
  font-size: 16px;
	color: #b5d9ff;
  line-height: 2;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}


/* SECTION IV - TESTIMONIALS SECTION */
/* slider */
// .se_iv .slick-list {
//   margin: 0 -10px;
// }
// .se_iv .slick-slide {
//   margin: 0 10px;
// }
// /* item */
// .se_iv .item {
//   outline: none;
//   padding: 40px 30px 30px;
//   background: #0a0a0a url(../images/icons/writer.svg) no-repeat;
//   background-size: 40px;
//   background-position: 20px 20px;
//   border: 1px solid #000;
//   border-radius: 16px;
// }
// /* text */
// .se_iv .item_text {
//   font-size: 16px;
//   color: #999;
//   display: -webkit-box;
//   -webkit-line-clamp: 4;
//   -webkit-box-orient: vertical;
//   overflow: hidden;
//   line-height: 1.8;
// }
// /* avatar */
// .se_iv .item .item_avatar {
//   width: 52px;
//   border-radius: 50%;
// }
// /* name & url */
// .se_iv .item .user_name {
//   color: #fff;
//   font-weight: 500;
//   font-size: 16px;
// }
// /* job */
// .se_iv .item .user_job {
//   font-size: 14px;
//   color: #999;
// }
// /* slider dots */
// .se_iv .slider_dots ul {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   list-style: none;
//   padding: 0;
//   margin-bottom: 0;
// }
// .se_iv .slider_dots li {
//   margin: 0 8px;
// }
// .se_iv .slider_dots li button {
//   text-indent: 100px;
//   padding: 0;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 6px;
//   height: 6px;
//   border-radius: 50%;
//   overflow: hidden;
//   opacity: .3;
//   transition: all .25s;
// }
// .se_iv .slider_dots li.slick-active button {
//   opacity: 1;
//   background: var(--co-1);
// }
/* --------------------------------- */

.service-section {
  h3 {
    color: #fff;
  }
  h2 {
    text-shadow: 8px 8px 0px #003b7a4d;
  }
}
.service-section .lg_box {
  height: 400px;
}
.service-section .sm_box {
  height: 185px;
}
/* --------------------------------- */

.portfolio-section .item {
  padding: 20px 30px;
  width: 100%;
  background: rgba(0, 0, 0, .1);
  border-radius: 16px;
}
.portfolio-section .item img {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
  object-fit: contain;
}
.portfolio-section .item p {
  color: #fff;
  font-weight: 900;
  font-size: 16px;
  font-family: 'GT-Walsheim', 'Poppins', sans-serif;
}

.portfolio-section .item_1 {
  background: rgba(0, 118, 157, 0.2);
}
.portfolio-section .item_2 {
  background: rgba(255, 193, 7, 0.2);
}
.portfolio-section .item_3 {
  background: rgba(115, 160, 251, 0.2);
}
.portfolio-section .item_4 {
  background: rgba(93, 64, 55, 0.2);
}
.portfolio-section .item_5 {
  background: rgba(241, 101, 40, 0.2);
}
.portfolio-section .item_6 {
  background: rgba(255, 70, 140, 0.2);
}
.portfolio-section .item_7 {
  background: rgba(248, 217, 255, 0.2);
}
.portfolio-section .timeline .group:not(:last-child) {
  margin-bottom: 60px;
}
.portfolio-section .timeline .year {
  min-width: 75px;
  font-size: 1.3em;
  font-weight: bold;
  color: var(--co-1);
  padding: 11px 15px;
  border-radius: 6px;
  background: #181818;
  font-family: "GT-Walsheim", "Poppins", sans-serif;
}

.portfolio-section .timeline .job_box:not(:last-child) {
  margin-bottom: 15px;
}
.portfolio-section .timeline .job_box .title {
  color: #FFF;
  font-size: 20px;
  font-weight: 500;
  font-family: 'GT-Walsheim', 'Poppins', sans-serif;
}
.portfolio-section .timeline .job_box .location {
  font-size: 14px;
  font-weight: 500;
  color: var(--gray);
}

@media (max-width: 1199.98px) {
  /* item */
  .portfolio-section .item {
    padding: 30px;
    width: 100%;
    height: unset;
  }
  .portfolio-section .item img {
    width: 70px;
    margin-bottom: 15px;
  }
  .portfolio-section .item p {
    font-size: 20px;
  }
  /* job box */
  .portfolio-section .timeline .job_box {
    padding: 20px;
    border: 1px solid var(--co-4);
    background: #0a0a0a ;
    border-radius: 16px;
  }
  .portfolio-section .timeline .job_box:not(:last-child) {
    margin-bottom: 30px;
  }
}
@media (max-width: 767.98px) {
  /* item */
  .portfolio-section .item img {
    width: 50px;
  }
}

/* --------------------------------- */

/* social list */
.social_list a {
  font-size: 30px;
  color: #fff;
  transition: all .25s;
}
.social_list a:hover {
  color: var(--co-1);
}
/* --------------------------------- */

/* GALLERY MODAL */
.gallery_modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 150px 0;
  background: rgba(0, 0, 0, .95);
  z-index: 98;
  overflow-y: auto;
}
.gallery_modal::-webkit-scrollbar {
  width: 4px;
}
.gallery_modal::-webkit-scrollbar-thumb {
  background: var(--co-1);
}
/* dismiss button */
.gallery_modal .dismiss_btn {
  position: fixed;
  top: 40px;
  right: 40px;
  background: transparent;
  padding: 0;
  color: #FFF;
  height: 48px;
  width: 48px;
  border: 2px solid #FFF;
  font-size: 20px;
  border-radius: 50%;
  transition: all .25s;
}
.gallery_modal .dismiss_btn:hover {
  background: #FFF;
  color: var(--co-4);
}
/* box */
.gallery_modal .box {
  max-width: 800px;
}
/* title */
.gallery_modal .title {
  font-size: 36px;
  font-weight: 900;
  color: #FFF;
  font-family: 'GT-Walsheim', 'Poppins', sans-serif;
}
/* description */
.gallery_modal .des {
  font-size: 19px;
  color: #ccc;
  line-height: 2em;
  p {
    margin-bottom: 1em;
  }
}
/* image */
.gallery_modal img {
  border-radius: 20px;
}
@media (max-width: 991.98px) {
  .gallery_modal .dismiss_btn {
    top: 20px;
    right: 29px;
  }
}
/* --------------------------------- */

/* Message Notification */
.message_notification {
  display: none;
  position: fixed;
  left: 50%;
  top: 30px;
  transform: translateX(-50%);
  background-color: #03C4A1;
  padding: 14px 28px;
  border-radius: 50px;
  z-index: 98
}
.message_notification.message_notification_2 {
  background-color: #F05454;
}
.message_notification p {
  margin-bottom: 0;
  font-size: 14px;
  color: #ffffff
}
/* --------------------------------- */

.feature-section .text {
  padding: 150px 0;
}
.feature-section .text p {
  font-size: 18px;
	color: #999;
  line-height: 2.2;
}

.feature-section .links_cont .bar {
  height: 2px;
  width: 60px;
  background: #666;
}

.feature-section .links_cont .social_links a {
  font-size: 20px;
  color: #FFF;
  transition: all .25s;
}
.feature-section .links_cont .social_links a:hover {
  color: var(--co-1);
}
@media(max-width: 991.98px) {
  .feature-section {
    background-image: unset !important;
    background-color: #0a0a0a;
  }
  .feature-section .text h2 {
    font-size: 50px;
  }
}
@media(max-width: 767.98px) {
  .feature-section .text {
    padding: 120px 0;
  }
}

.theme-section {
  h3 {
    padding: 10px 0;
  }

  h2 {
    font-size: 70px;
    margin-top: 30px;

    .underline {
      position: relative;
      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 110%;
        bottom: 12px;
        left: -5%;
        border-bottom: 30px solid var(--co-1);
        z-index: -1;
        transform: skewX(30deg);
      }
    }
  }

  .text {
    p {
      color: #fff;
    }
  }

  .links_cont {
    .bar {
      background: #fff;
    }
  }
}

.foot-section {
  background: var(--primary);
}

@media (max-width: 576px) {
  .home-section {
    h1 {
      padding: 80px 0;
    }
  }
  .theme-section {
    .text {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}